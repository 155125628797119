import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { get } from 'lodash';

import { Button } from 'shared';
import { IconEdit, IconRemove } from '../Icons';
import 'react-table/react-table.css';
import './styles.scss';

const Table = ({
  clickable,
  columns,
  data,
  defaultPageSize,
  handleClick,
  resizable,
  selectedRow,
  loadingText,
  trClassKey,
  trClassByProp,
  subRowsKey,
  expandedRows,
  SubComponent,
  stickyHeader,
  checkLogTypeDetails,
  isCompact,
  enableEdit,
  enableDelete,
  onEdit,
  onDelete,
  isActionsDisabled,
  showPagination,
  isInfiniteScroll,
  fetchMoreData,
  filters,
  ...rest
}) => {
  const tableRef = useRef(null);
  const isFetchingRef = useRef(false);

  useEffect(() => {
    if (document.querySelector('.ReactTable .rt-tbody .rt-tr-group:first-child') && !isFetchingRef.current) {
      document.querySelector('.ReactTable .rt-tbody .rt-tr-group:first-child')
        .scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [JSON.stringify(filters)]); // Trigger when filters change

  useEffect(() => {
    if (isInfiniteScroll && data?.length > 0) {
      const observer = new IntersectionObserver(
        async ([entry]) => {
          if (entry.isIntersecting && !isFetchingRef.current) {
            isFetchingRef.current = true; // Set fetching state to true
            await fetchMoreData();
            isFetchingRef.current = false; // Reset fetching state after data is fetched
          }
        },
        {
          root: tableRef.current, // Observe within the table container
          rootMargin: '500px',
          threshold: 0.1,
        },
      );

      const lastRow = document.querySelector('.ReactTable .rt-tbody .rt-tr-group:last-child');
      if (lastRow) {
        observer.observe(lastRow);
      }

      return () => {
        if (lastRow) {
          observer.unobserve(lastRow);
        }
        observer.disconnect(); // Disconnect the observer to reset it
      };
    }
  }, [isInfiniteScroll, data, fetchMoreData, JSON.stringify(filters)]); // Add filters as a dependency

  const enhancedColumns = [...columns];

  if (enableEdit && data?.length > 0) {
    enhancedColumns.push({
      Header: '',
      // eslint-disable-next-line react/prop-types
      Cell: ({ original }) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ width: 55, padding: '5px 8px' }}
            onClick={(e) => { e.stopPropagation(); onEdit(original); }}
            disabled={isActionsDisabled}
          >
            <IconEdit
              height="14px"
              width="14px"
              fill="#4285F4"
            />
          </Button>
        </div>
      ),
      width: 75,
      sortable: false,
    });
  }

  if (enableDelete && data?.length > 0) {
    enhancedColumns.push({
      Header: '',
      // eslint-disable-next-line react/prop-types
      Cell: ({ original }) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ width: 55, padding: '5px 8px' }}
            onClick={(e) => { e.stopPropagation(); onDelete(original); }}
            disabled={isActionsDisabled}
          >
            <IconRemove
              height="14px"
              width="14px"
              fill="#4285F4"
            />
          </Button>
        </div>
      ),
      width: 75,
      sortable: false,
    });
  }

  const getTrProps = (state, rowInfo) => {
    const rowProps = {
      onClick: (e) => handleClick(rowInfo, e),
      className: `${(selectedRow === get(rowInfo, 'row._original.id')) ? 'selected' : ''}
        ${(trClassKey !== '' && get(rowInfo, `row._original.${trClassKey}`) !== null) ? `${trClassKey}--${get(rowInfo, `row._original.${trClassKey}`)}` : ''}
        ${(trClassByProp !== '' && get(rowInfo, `row._original.${trClassByProp}`) !== null && get(rowInfo, `row._original.${trClassByProp}`) !== '' && get(rowInfo, `row._original.${trClassByProp}`) !== 0) ? `${trClassByProp}--true` : `${trClassByProp}--false`}`,
    };

    if (checkLogTypeDetails) {
      const logType = get(rowInfo, 'row._original.log_type');
      if (logType === 'order_end' && rowInfo.original.details) {
        let details;
        if (typeof rowInfo.original.details === 'string' && rowInfo.original.details.trim().charAt(0) === '{' && rowInfo.original.details.trim().charAt(rowInfo.original.details.trim().length - 1) === '}') {
          details = JSON.parse(rowInfo.original.details);
        }
        if (details && details.booking_type === 'partial') {
          rowProps.className += ' orange';
        }
      }
    }

    return rowProps;
  };

  return (
    <div className={`Table ${clickable ? 'Table-clickable' : ''}`}>
      <div ref={tableRef} className={`${stickyHeader ? 'table-wrapper' : ''}`}>
        <ReactTable
          className={`${isCompact && 'compact'} -striped`}
          columns={enhancedColumns}
          data={data}
          defaultPageSize={defaultPageSize}
          showPagination={showPagination}
          subRowsKey={subRowsKey}
          expandedRows={expandedRows}
          SubComponent={SubComponent}
          getTrProps={getTrProps}
          getTdProps={() => ({
            style: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
          })}
          resizable={resizable}
          showPageSizeOptions={false}
          loadingText={loadingText || 'Loading...'}
          noDataText=" "
          manual="true"
          {...rest}
        />
      </div>
    </div>
  );
};

Table.propTypes = {
  clickable: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  handleClick: PropTypes.func,
  resizable: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  selectedRow: PropTypes.number,
  loadingText: PropTypes.string,
  trClassKey: PropTypes.string,
  trClassByProp: PropTypes.string,
  subRowsKey: PropTypes.string,
  expandedRows: PropTypes.bool,
  SubComponent: PropTypes.func,
  stickyHeader: PropTypes.bool,
  checkLogTypeDetails: PropTypes.bool,
  isCompact: PropTypes.bool,
  enableEdit: PropTypes.bool,
  enableDelete: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isActionsDisabled: PropTypes.bool,
  showPagination: PropTypes.bool,
  isInfiniteScroll: PropTypes.bool,
  fetchMoreData: PropTypes.func,
  filters: PropTypes.object,
};

Table.defaultProps = {
  clickable: false,
  defaultPageSize: 20,
  handleClick: () => { },
  resizable: false,
  trClassKey: '',
  subRowsKey: '',
  expandedRows: false,
  SubComponent: undefined,
  stickyHeader: false,
  isCompact: true,
  enableEdit: false,
  enableDelete: false,
  onEdit: () => {},
  onDelete: () => {},
  isActionsDisabled: false,
  showPagination: false,
  isInfiniteScroll: false,
  fetchMoreData: () => {},
  filters: {},
};

export default Table;
